import(/* webpackMode: "eager" */ "/var/www/manga.online/build/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["SkeletonTheme"] */ "/var/www/manga.online/build/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/manga.online/build/src/app/providers/QueryProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/manga.online/build/src/app/providers/UserSettingsProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/manga.online/build/src/app/ui/layout/FooterWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderWrapper"] */ "/var/www/manga.online/build/src/app/ui/layout/HeaderWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/manga.online/build/src/app/ui/layout/LayoutWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/manga.online/build/src/app/ui/layout/ReactNotificationWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/manga.online/build/src/app/ui/Modal/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/manga.online/build/src/components/providers/ModalProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/manga.online/build/src/components/providers/SessionProviderWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/manga.online/build/src/components/providers/ThemeProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/manga.online/build/src/components/providers/TooltipProviderWrapper/index.tsx");
